import React, { createContext, useState, useContext, useCallback, useEffect } from 'react';
import { login as apiLogin, fetchCurrentUser } from '../services/api';
import api from '../services/api';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import { getSignedUrl } from '../services/api';
import { notifyProfilePictureUpdate } from '../utils/profilePictureUtils';

const UserContext = createContext();

export function UserProvider({ children }) {
  const [user, setUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [authLoading, setAuthLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [hideTime, setHideTime] = useState(false);

  const checkAuth = useCallback(async () => {
    setAuthLoading(true);
    try {
      const token = Cookies.get('token');
      if (token) {
        const decodedToken = jwtDecode(token);
        const userData = await fetchCurrentUser();
        setUser({ 
          ...userData, 
          permission: decodedToken.permission || userData.permission,
          clientId: userData.clientId,
          // Add these new fields
          totalActualHoursLogged: userData.totalActualHoursLogged || 0,
          totalBillableHours: userData.totalBillableHours || 0,
          timeHide: userData.timeHide || false,
          rate: userData.rate,
          assignedRole: userData.assignedRole,
          client: userData.client
        });
        setIsAuthenticated(true);
      } else {
        setUser(null);
        setIsAuthenticated(false);
      }
    } catch (error) {
      console.error('Error checking auth:', error);
      setUser(null);
      setIsAuthenticated(false);
    } finally {
      setAuthLoading(false);
    }
  }, []);

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  const login = async (email, password) => {
    setAuthLoading(true);
    try {
      const { token, user } = await apiLogin(email, password);
      const decodedToken = jwtDecode(token);
      const completeUserData = { 
        ...user, 
        permission: decodedToken.permission || user.permission,
        clientId: user.clientId
      };
      setUser(completeUserData);
      setIsAuthenticated(true);
      Cookies.set('token', token);
      return { token, user: completeUserData };
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    } finally {
      setAuthLoading(false);
    }
  };

  // Add these new functions
  const fetchUserAIApps = useCallback(async (userId) => {
    try {
      const response = await api.get(`/users/${userId}/ai-apps`);
      return response.data;
    } catch (error) {
      console.error('Error fetching user AI apps:', error);
      throw error;
    }
  }, []);

  const assignAIAppToUser = async (userId, aiAppId) => {
    try {
      await api.post(`/ai-apps/${aiAppId}/users/${userId}`);
      // Optionally update the local state if needed
    } catch (error) {
      console.error('Error assigning AI app to user:', error);
      throw error;
    }
  };

  const updateUserAIApps = async (userId, aiAppIds) => {
    try {
      const response = await api.put(`/users/${userId}/ai-apps`, { aiAppIds });
      if (user && user.id === userId) {
        setUser(prevUser => ({ ...prevUser, aiApps: response.data }));
      }
      return response.data;
    } catch (error) {
      console.error('Error updating user AI apps:', error);
      throw error;
    }
  };

  const toggleTimeHide = useCallback(async (userId) => {
    try {
      const response = await api.patch(`/users/${userId}/toggle-time-hide`);
      const updatedUser = response.data;

      setUsers(prevUsers => prevUsers.map(u => u.id === userId ? updatedUser : u));
      if (user && user.id === userId) {
        setUser(updatedUser);
      }

      return updatedUser;
    } catch (error) {
      console.error('Error toggling time hide:', error);
      throw error;
    }
  }, [user]);

  const toggleHideTime = useCallback(() => {
    setHideTime(prevHideTime => !prevHideTime);
  }, []);

  const fetchUsers = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get('/users');
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const addUser = async (userData) => {
    try {
      const response = await api.post('/users', userData);
      // Only add the new user if it's not deleted
      if (!response.data.isDeleted) {
        setUsers(prevUsers => [...prevUsers, response.data]);
      }
      return response.data;
    } catch (error) {
      console.error('Error adding user:', error);
      throw error;
    }
  };

  const updateUser = async (userId, userData) => {
    try {
      console.log('Updating user with data:', userData); // Add this line
      const response = await api.put(`/users/${userId}`, userData);
      setUsers(prevUsers => prevUsers.map(u => u.id === userId ? response.data : u)
                                     .filter(u => !u.isDeleted));
      if (user && user.id === userId) {
        setUser(response.data);
      }
      return response.data;
    } catch (error) {
      console.error('Error updating user:', error.response?.data || error.message);
      throw error;
    }
  };

  const deleteUser = async (userId) => {
    try {
      await api.delete(`/users/${userId}`);
      setUsers(prevUsers => prevUsers.filter(u => u.id !== userId));
    } catch (error) {
      console.error('Error deleting user:', error);
      throw error;
    }
  };

  const checkUserPermission = (requiredPermission) => {
    return user && user.permission === requiredPermission;
  };

  const changeUserRole = async (userId, newRoleId) => {
    try {
      const response = await api.put(`/users/${userId}`, { roleId: newRoleId });
      setUsers(users.map(u => u.id === userId ? response.data : u));
      if (user && user.id === userId) {
        setUser(response.data);
      }
      return response.data;
    } catch (error) {
      console.error('Error changing user role:', error);
      throw error;
    }
  };

  const checkUserRole = (requiredRoles) => {
    return user && requiredRoles.includes(user.role);
  };

  const logout = useCallback(() => {
    Cookies.remove('token');
    setUser(null);
    setIsAuthenticated(false);
  }, []);

  const fetchAllUsers = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get('/users/all');
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching all users:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchUserActualHours = useCallback(async (userId) => {
    try {
      const response = await api.get(`/users/${userId}/actual-hours`);
      return response.data;
    } catch (error) {
      console.error('Error fetching user actual hours:', error);
      throw error;
    }
  }, []);

  const fetchUserBillableHours = useCallback(async (userId) => {
    try {
      const response = await api.get(`/users/${userId}/billable-hours`);
      return response.data;
    } catch (error) {
      console.error('Error fetching user billable hours:', error);
      throw error;
    }
  }, []);

  const createAuditLog = useCallback(async (action, details) => {
    if (!user || !user.id) {
      console.error('Cannot create audit log: User is not authenticated');
      return;
    }
    try {
      await api.post('/audit-logs', {
        userId: user.id,
        action,
        details
      });
    } catch (error) {
      console.error('Error creating audit log:', error);
    }
  }, [user]);

  // Add these new functions to the UserProvider component

  const uploadProfilePicture = async (userId, file) => {
    try {
      const formData = new FormData();
      formData.append('file', file);

      const response = await api.post(`/users/${userId}/profile-picture`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // Check if response has the expected structure
      if (!response.data || (!response.data.success && !response.data.url)) {
        throw new Error(response.data?.error || 'Failed to upload profile picture');
      }

      const profilePictureUrl = response.data.url;

      // Update local state
      setUsers(prevUsers => 
        prevUsers.map(u => 
          u.id === userId 
            ? { ...u, profilePicture: profilePictureUrl }
            : u
        )
      );

      // Update current user if it's their profile picture
      if (user && user.id === userId) {
        setUser(prevUser => ({
          ...prevUser,
          profilePicture: profilePictureUrl
        }));
      }

      // Cache the new URL
      localStorage.setItem(`profile_picture_${userId}`, JSON.stringify({
        url: profilePictureUrl,
        timestamp: Date.now()
      }));

      // Notify all components about the update
      notifyProfilePictureUpdate(userId);

      return {
        success: true,
        profilePicture: profilePictureUrl
      };
    } catch (error) {
      console.error('Error uploading profile picture:', error);
      throw error;
    }
  };

  const deleteProfilePicture = async (userId) => {
    try {
      const response = await api.delete(`/users/${userId}/profile-picture`);
      
      if (!response.data.success && response.status !== 204) {
        throw new Error('Failed to delete profile picture');
      }

      // Clear the cache for this user
      localStorage.removeItem(`profile_picture_${userId}`);

      // Update local state
      setUsers(prevUsers => 
        prevUsers.map(u => 
          u.id === userId 
            ? { ...u, profilePicture: null }
            : u
        )
      );

      // Update current user if it's their profile picture
      if (user && user.id === userId) {
        setUser(prevUser => ({
          ...prevUser,
          profilePicture: null
        }));
      }

      // Notify all components about the update
      notifyProfilePictureUpdate(userId);

      return { success: true };
    } catch (error) {
      console.error('Error deleting profile picture:', error);
      throw error;
    }
  };

  const getProfilePicture = async (userId) => {
    try {
      // Check cache first
      const cachedUrl = localStorage.getItem(`profile_picture_${userId}`);
      if (cachedUrl) {
        const cached = JSON.parse(cachedUrl);
        if (Date.now() - cached.timestamp < 15 * 60 * 1000) { // 15 minutes
          return cached.url;
        }
      }

      const response = await api.get(`/users/${userId}/profile-picture`);
      if (response.data.success && response.data.url) {
        // Cache the URL
        localStorage.setItem(`profile_picture_${userId}`, JSON.stringify({
          url: response.data.url,
          timestamp: Date.now()
        }));
        return response.data.url;
      }
      return null;
    } catch (error) {
      // Don't log 404 errors as they're expected when no profile picture exists
      if (error.response?.status !== 404) {
        console.error('Error fetching profile picture:', error);
      }
      return null;
    }
  };

  const getProfilePictureUrls = async (userIds) => {
    try {
      if (!userIds?.length) return {};
      
      // Check cache first for all IDs
      const cachedUrls = {};
      const uncachedIds = [];
      
      userIds.forEach(id => {
        const cached = localStorage.getItem(`profile_picture_${id}`);
        if (cached) {
          const { url, timestamp } = JSON.parse(cached);
          if (Date.now() - timestamp < 15 * 60 * 1000) {
            cachedUrls[id] = url;
            return;
          }
        }
        uncachedIds.push(id);
      });

      // Only fetch uncached URLs
      if (uncachedIds.length > 0) {
        const response = await api.get(`/users/profile-pictures?ids=${uncachedIds.join(',')}`);
        const newUrls = response.data || {};
        
        // Cache new URLs
        Object.entries(newUrls).forEach(([id, url]) => {
          localStorage.setItem(`profile_picture_${id}`, JSON.stringify({
            url,
            timestamp: Date.now()
          }));
        });

        return { ...cachedUrls, ...newUrls };
      }

      return cachedUrls;
    } catch (error) {
      console.error('Error fetching profile picture URLs:', error);
      return {};
    }
  };

  // Make sure it's included in the value object
  const value = {
    user,
    users,
    loading,
    fetchUsers,
    fetchAllUsers,
    addUser,
    updateUser,
    deleteUser,
    login,
    logout,
    checkUserPermission,
    changeUserRole,
    toggleTimeHide,
    checkAuth,
    isAuthenticated,
    authLoading,
    hideTime,
    toggleHideTime,
    checkUserRole,
    fetchUserAIApps,
    assignAIAppToUser,
    updateUserAIApps,
    fetchUserActualHours,
    fetchUserBillableHours,
    createAuditLog,
    uploadProfilePicture,
    deleteProfilePicture,
    getProfilePicture,
    getProfilePictureUrls, // Make sure this is included
  };

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
}

export function useUser() {
  return useContext(UserContext);
}
